import axios from "axios";
import { backendBaseURL } from "../../utils/constants/services/services-constants";
import { ROUTES } from "../routes/routes";

const serviceRest = axios.create({
  baseURL: backendBaseURL,
});

export default serviceRest;

export const registerServiceRestInterceptors = (history) => {
  serviceRest.interceptors.response.use(
    // success
    (response) => response,

    // error
    (error) => {

      const errorHasResponse = Boolean(error && error.response);

      if (errorHasResponse && error.response.status === 429) {
        history.push(ROUTES.STATUS_429.path)
      }

      return Promise.reject(error);
    },
  );
};