import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { actions as userActions } from "../../redux/modules/usuario";

class RedirectContainer extends PureComponent {
  componentDidMount() {
    this.props.safeLogout();
  }

  render() {
    const { url } = this.props.computedMatch;
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { redirectUrl: url },
        }}
      />
    );
  }
}

const mapDispatchToProps = {
  safeLogout: userActions.simpleLogoutUser,
};

export default connect(null, mapDispatchToProps)(RedirectContainer);
