/* eslint-disable no-unused-vars */
import _ from "lodash";
import React, { Component } from "react";
import { Route } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";

import RedirectContainer from "./RedirectContainer";

import { selectors as userSelectors } from "../../redux/modules/usuario";
import Status404 from "./Status404";

class PrivateRoute extends Component {
  render() {
    const {
      isAuthenticated,
      userRoles,
      component: Component,
      ...rest
    } = this.props;

    const isAuthorized =
      _.isEmpty(rest.roles) || userRoles.some((r) => rest.roles.includes(r));

    // usuário não está autenticado, mas a rota é privada
    // redirecionar para o logout
    if (!isAuthenticated) {
      return (
        <Route {...rest} render={(props) => <RedirectContainer {...rest} />} />
      );
    }

    // verificar se o usuário possui acesso à rota
    // redirecionar para o 404
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthorized === true ? <Component {...props} /> : <Status404 />
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    isAuthenticated: userSelectors.isAuthenticated(state),
    userRoles: userSelectors.getUserRoles(state),
  };
}

export default compose(connect(mapStateToProps))(PrivateRoute);
