import axios from "axios";
import { serviceBaseURL } from "../utils/constants/services/services-constants";
import { ROUTES } from "../commons/routes/routes";

const serviceAPI = axios.create({
  baseURL: serviceBaseURL,
});

export default serviceAPI;

export const registerServiceApiInterceptors = (history) => {
  serviceAPI.interceptors.response.use(
    // success
    (response) => response,

    // error
    (error) => {

      const errorHasResponse = Boolean(error && error.response);

      if (errorHasResponse && error.response.status === 429) {
        history.push(ROUTES.STATUS_429.path)
      }

      return Promise.reject(error);
    },
  );
};