import * as Dialog from "@radix-ui/react-dialog";
import classnames from "classnames";
import React, { PureComponent } from "react";
import { Form } from "redux-form";
import AppLoading from "../../../../AppLayout/containers/AppLoading";
import SoftSigner from "./softplan-websigner/SoftSigner.js";

import "./info-modal-styles.css";

export class AuthSignerDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.signer = new SoftSigner();
    this.myRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    initializing: true,
    certificates: [],
    isExtensionInstalled: false,
    selectedCert: "0",
    password: "",
    user: "",
    mapCertsCpfsUsers: new Map(),
    desafio: "",
    loading: false,
    email:"",
    mapCertsEmailsUsers:new Map(),
  };

  componentDidMount() {
    this.getCertificates();
  }

  getCertificates = async () => {
    try {
      if (this.props.tiposLoginAtivo.includes("certificado-digital")) {
        await this.checkExtensionInstalled();
      }
      const certs = await this.signer.getCertificates();
      const certsCpfsUsers = new Map();
      const certsEmailsUsers = new Map();

      certs.map((certificate) => certsCpfsUsers.set(certificate.thumbprint, certificate.pkiBrazil.cpf));
      certs.map((certificate) => certsEmailsUsers.set(certificate.thumbprint, certificate.email));

      this.setState({
        certificates: certs,
        isExtensionInstalled: true,
        initializing: false,
        mapCertsCpfsUsers: certsCpfsUsers,
        mapCertsEmailsUsers: certsEmailsUsers,
      });
    } catch (err) {
      console.error(`[error]: ${err}`);
      this.setState({ initializing: false });
    }
  };

  checkExtensionInstalled = () => {
    return new Promise((resolve, reject) => {
      this.signer
        .init({
          notInstalled: this.redirectToInstall,
        })
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  };

  redirectToInstall = () => {
    this.signer.redirectToInstallPage();
  };

  focus = () => {
    setTimeout(() => {
      this.myRef.current.focus();
    }, 100);
  };

  handleSetSelectedCert = (e) => {
    this.setState({
      selectedCert: e.target.value,
      user: this.state.mapCertsCpfsUsers.get(e.target.value),
      email: this.state.mapCertsEmailsUsers.get(e.target.value),

    });
    this.handleSigner(e);
  };

  handleOnCloseDialog = () => {
    this.setState({
      selectedCert: null,
      password: "",
      user: "",
      desafio: "",
    });
  };

  handleSubmit() {
    this.setloading(true);

    let payload = {
      usuario: this.state.user,
      senha: this.state.password,
      certificado: this.state.certificado,
      desafio: this.state.desafio,
      email: this.state.email,
    };

    this.props.login(payload);

    this.setloading(false);
  }

  setloading = (param) => {
    this.setState({
      loading: param,
    });
  };

  setCertificado = (certEncoding) => {
    this.setState({
      certificado: certEncoding,
    });
  };

  setPassword = (hash) => {
    this.setState({
      password: hash,
    });
  };

  handleSigner = async (e) => {
    const randomDesafio = window.btoa(Math.random());

    this.setState({
      desafio: randomDesafio,
    });

    const req = {
      thumbprint: e.target.value,
      data: randomDesafio,
    };

    this.setCertificado(await this.signer.loadCertificate(e.target.value));
    this.setPassword(await this.signer.signData(req));
  };

  selectOptionFormatter = (cert) => {
    // eslint-disable-next-line no-useless-concat
    let text = cert.subjectName + " (" + "Válido até " + this.formattedDate(cert.validityEnd) + ")";
    if (new Date() > cert.validityEnd) {
      text = "[EXPIRADO] " + text;
    }
    return text;
  };

  formattedDate = (date) => {
    var d = new Date(date || Date.now()),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [month, day, year].join("/");
  };

  render() {
    return (
      <React.Fragment>
        <Dialog.Root>
          <Dialog.Trigger asChild>
            <button className="br-button primary mr-3" type="button" label="Entrar com certificado digital" onClick={this.focus} tabIndex={9}>
              Entrar com Certificado Digital
            </button>
          </Dialog.Trigger>

          <Form onSubmit={this.handleSubmit}>
            <Dialog.Portal>
              <Dialog.Overlay className="info-modal-overlay" />
              <Dialog.Content className="info-modal-content">
                <Dialog.Title className="info-modal-title" ref={this.myRef} tabIndex={-1}>
                  Login com Certificado Digital
                </Dialog.Title>

                <div>
                  <AppLoading show={this.props.loading} />
                  <section>
                    <div className="groupDiv">
                      <div>
                        <select
                          defaultValue={this.state.selectedCert}
                          className={classnames("sds-select sds-input")}
                          onChange={this.handleSetSelectedCert}>
                          <option value="0">---------------------- Selecione um certificado digital ----------------------</option>
                          {this.state.certificates.map((certificate, index) => (
                            <option key={index} value={certificate.thumbprint}>
                              {this.selectOptionFormatter(certificate)}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div></div>
                    </div>
                  </section>
                  <br />
                  <div className="sds-form-group login-content">
                    <button
                      className="br-button primary mr-3 sds-btn--full-width"
                      label="entrar"
                      tabIndex={9}
                      onClick={this.handleSubmit}
                      disabled={this.state.selectedCert === 0}>
                      Entrar
                    </button>
                  </div>
                </div>
                <Dialog.Close asChild>
                  <button className="info-modal-button-close" aria-label="Fechar Informações de Contato" onClick={this.handleOnCloseDialog}>
                    <i className="fa fa-times info-modal-icon-close" aria-hidden="true"></i>
                  </button>
                </Dialog.Close>
              </Dialog.Content>
            </Dialog.Portal>
          </Form>
        </Dialog.Root>
      </React.Fragment>
    );
  }
}

export default AuthSignerDialog;
