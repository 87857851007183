import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { injectIntl } from "react-intl";

import LoginAuth from "./containers/login-auth/login-auth";

import {
  types as userTypes,
  actions as userActions,
  selectors as userSelectors,
} from "../../../redux/modules/usuario";
import {
  actions as flowActions,
  selectors as flowSelectors,
} from "../../../redux/modules/flow";
import {
  consts as cfgConsts,
  selectors as cfgSelectors,
} from "../../../redux/modules/configuracoes";
import { ROUTES } from "../../../commons/routes/routes";
import { TabTitle } from "../../../utils/functions/GeneralFunctions";

class Login extends PureComponent {
  componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push(ROUTES.MINHAS_SOLICITACOES.path);
    }

    const { intl } = this.props;
    const pageTitle = intl.formatMessage({id: "LABELS.HOME.SERVICES.LOGIN.TITLE" });
    const systemTitle = intl.formatMessage({id: "LABELS.COMMONS.NOME_SISTEMA" });
    const clientTitle = intl.formatMessage({id: "LABELS.COMMONS.NOME_CLIENTE" });
    TabTitle(`${pageTitle} - ${systemTitle} - ${clientTitle}`,);
  }

  componentWillUnmount() {
    this.props.reset(userTypes.USER_LOGIN);
  }

  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function (item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }

  render() {
    const { loading, login, openModal, captchaEnabled, captchaSitekey, intl, tiposLoginAtivo } =
      this.props;
    const redirectUrl = _.get(this.props, "location.state.redirectUrl");
    let code = this.findGetParameter("code");

    if (code !== undefined && code !== "" && code === "access_denied") {
      code = intl.formatMessage({id: "LABELS.VALIDACAO.INTEGRACAO_GOVBR" });
    }

    return (
      <LoginAuth
        loading={loading}
        erro={code}
        login={login}
        openModal={openModal}
        redirectUrl={redirectUrl}
        captchaEnabled={captchaEnabled}
        captchaSitekey={captchaSitekey}
        tiposLoginAtivo={tiposLoginAtivo}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    loading: flowSelectors.isLoadingByType(state, userTypes.USER_LOGIN),
    erro: flowSelectors.getErrorByType(state, userTypes.USER_LOGIN),
    captchaEnabled:
      cfgSelectors
        .getValorConfiguracao(state, cfgConsts.GOOGLE_RECAPTCHA_ENABLED)
        .toUpperCase() === "TRUE",
    captchaSitekey: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.GOOGLE_RECAPTCHA_SITE_KEY,
    ),
    isAuthenticated: userSelectors.isAuthenticated(state),
    tiposLoginAtivo: cfgSelectors.getValorConfiguracao(
      state,
      cfgConsts.TIPOS_LOGIN_ATIVOS,
    ),
  };
}

const mapDispatchToProps = {
  login: userActions.loginUser,
  reset: flowActions.reset,
};

export default compose(  
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
)(Login);
