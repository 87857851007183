import _ from "lodash";
import moment from "moment";

import { createAction, handleActions } from "redux-actions";
import { put, call } from "redux-saga/effects";

import { actions as modalActions } from "../../redux/modules/modal";

// ACTION TYPES
const RESET = `${process.env.PUBLIC_URL}/FLOW/RESET`;
const START = `${process.env.PUBLIC_URL}/FLOW/START`;
const PROGRESS = `${process.env.PUBLIC_URL}/FLOW/PROGRESS`;
const SUCCESS = `${process.env.PUBLIC_URL}/FLOW/SUCCESS`;
const FAIL = `${process.env.PUBLIC_URL}/FLOW/FAIL`;

export const types = {
  RESET,
  START,
  SUCCESS,
  PROGRESS,
  FAIL,
};

// ACTIONS
const reset = createAction(RESET);
const start = createAction(START);
const progress = createAction(PROGRESS);
const success = createAction(SUCCESS);
const fail = createAction(FAIL);

export const actions = {
  reset,
  start,
  progress,
  success,
  fail,
};

// INITIAL STATE
const initialState = {};

// REDUCERS
const reducer = handleActions(
  {
    [START]: (state, action) => {
      const type = action.payload;
      return { ...state, [type]: { loading: true, done: false } };
    },
    [SUCCESS]: (state, action) => {
      const type = action.payload;
      return { ...state, [type]: { loading: false, done: true } };
    },
    [PROGRESS]: (state, action) => {
      const { type } = action.payload;
      const oldProgress = state[type].progress || {};

      let proximaTarefa = 0;
      if (oldProgress.atual !== undefined) {
        proximaTarefa = oldProgress.atual + 1;
      }

      const progress = {
        ...oldProgress,
        ...action.payload.progress,
        atual: proximaTarefa,
      };

      return { ...state, [type]: { ...state[type], progress } };
    },
    [FAIL]: (state, action) => {
      const { type, erro_tipo, erro_mensagem, erro_data } = action.payload;
      return {
        ...state,
        [type]: {
          loading: false,
          done: true,
          erro_tipo,
          erro_mensagem,
          erro_data,
        },
      };
    },
    [RESET]: (state, action) => {
      const types = action.payload;
      return _.omit(state, types);
    },
  },
  initialState,
);

export default reducer;

// SELECTORS
const isLoading = (state) => {
  return (
    undefined !==
    _.find(state.flow, function (f) {
      return f.loading === true;
    })
  );
};

const isLoadingByType = (state, ...types) => {
  const loading = _.find(types, (type) => {
    return Boolean((state.flow[type] || {}).loading);
  });
  return loading !== undefined;
};

const getErrorByType = (state, ...types) => {
  const flow = _.map(types, (type) => state.flow[type] || {}).find((f) => {
    return f.erro_tipo === "NEGOCIO" && Boolean(f.erro_mensagem);
  });

  if (flow === undefined) {
    return undefined;
  }
  return flow.erro_mensagem;
};

const isSuccessByType = (state, ...types) => {
  const flows = _.map(types, (type) => state.flow[type] || {}).filter((f) => {
    const { loading, erro_mensagem } = f;
    return Boolean(loading === false && erro_mensagem === undefined);
  });

  return flows.length === types.length;
};

const isDoneByType = (state, ...types) => {
  const flows = _.map(types, (type) => state.flow[type] || {}).filter((f) => {
    return f.done;
  });

  return flows.length === types.length;
};

const getProgressByType = (state, type) => {
  const flow = state.flow[type] || {};
  return flow.progress;
};

export const selectors = {
  isLoading,
  isLoadingByType,
  isSuccessByType,
  isDoneByType,
  getErrorByType,
  getProgressByType,
};

// HELPERS
export function* flow(options) {
  const { type, fnTry, fnCatch, fnFinally } = options;

  try {
    // inicia o 'fluxo'
    yield put(actions.start(type));

    // invoca a função 'try'
    yield call(fnTry);

    // finaliza com sucesso o 'fluxo'
    yield put(actions.success(type));
  } catch (e) {
    console.error(e);
    try {
      if (fnCatch) {
        // invoca a função 'catch'
        yield call(fnCatch);
      }
    } finally {
      // error msg
      const erro = yield extrairErro(e, Boolean(fnCatch));

      // finaliza com o erro original o fluxo
      yield put(
        actions.fail({
          type,
          erro_tipo: erro.tipo,
          erro_mensagem: erro.mensagem,
          erro_data: erro.data,
        }),
      );
    }
  } finally {
    if (fnFinally) {
      // invoca a função 'finally'
      yield call(fnFinally);
    }
  }
}

function* extrairErro(e, isErroTratado) {
  let tipo = "RUNTIME";
  let mensagem = null;
  let data = null;

  const errorHasResponse = Boolean(e && e.response);
  if (errorHasResponse && e.response.status === 429) {
    yield put(
      modalActions.open({
        title: "LABELS.MESSAGES.COMMONS.ERRO_INESPERADO_TITULO",
        content: "LABELS.MESSAGES.COMMONS.STATUS_429"
      }),
    );
  } else {
    let backend = _.get(e, "response.data") || {};
  
    if (backend.tipo === "RUNTIME" || backend.tipo === "NEGOCIO") {
      tipo = backend.tipo;
    }
  
    if (backend.data) {
      data = backend.data;
    }
  
    if (backend.mensagem) {
      mensagem = backend.mensagem;
    }
  
    if (!mensagem) {
      mensagem = e.message;
    }
  
    if (!mensagem) {
      mensagem = "LABELS.MESSAGES.COMMONS.ERRO_INESPERADO_TITULO";
    }
  
    if (!data) {
      data = moment().format("DD/MM/YYYY HH:mm");
    }
  
    if (tipo === "RUNTIME" && !isErroTratado) {
      let conteudo = "LABELS.MESSAGES.COMMONS.ERRO_INESPERADO_CONTEUDO";
      if (_.startsWith(mensagem, "LABELS.")) {
        conteudo = mensagem;
      }
  
      yield put(
        modalActions.open({
          title: "LABELS.MESSAGES.COMMONS.ERRO_INESPERADO_TITULO",
          content: conteudo,
          params: { id: data },
        }),
      );
    }
  }

  return { tipo, mensagem, data };
}
