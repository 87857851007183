import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import "core-js/es6/string";
import "core-js/es6/array";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from "./config/theme";

import { configureStore } from "./redux/store";
import { registerAxiosInterceptors } from "./commons/http/axios";
import { registerServiceApiInterceptors } from "./services/serviceAPI";
import { registerServiceFormatInputInterceptors } from "./services/serviceFormatInput";
import { registerServiceTaskApiInterceptors } from "./services/serviceTaskAPI";
import { registerServiceRestInterceptors } from "./commons/services/serviceRest";
import { configureReactIntlPolyfill } from "./commons/i18n/intl";

import "./index.css";
import App from "./App/App";


function renderApp(store, history) {

  ReactDOM.render(
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <App history={history} />
      </MuiThemeProvider>
    </Provider>,
    document.querySelector("#root"),
  );
}

function startApp() {
  const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });
  const store = configureStore(history);

  registerAxiosInterceptors(store, history);
  registerServiceApiInterceptors(history);
  registerServiceFormatInputInterceptors(history);
  registerServiceTaskApiInterceptors(history);
  registerServiceRestInterceptors(history);

  configureReactIntlPolyfill(() => {
    renderApp(store, history);
  });
}

startApp();
