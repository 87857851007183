import axios from "axios";
import { backendBaseURL } from "../utils/constants/services/services-constants";
import { ROUTES } from "../commons/routes/routes";

const serviceTaskAPI = axios.create({
  baseURL: backendBaseURL,
});

export default serviceTaskAPI;

export const registerServiceTaskApiInterceptors = (history) => {
  serviceTaskAPI.interceptors.response.use(
    // success
    (response) => response,
  
    // error
    (error) => {
  
      const errorHasResponse = Boolean(error && error.response);
  
      if (errorHasResponse && error.response.status === 429) {
        history.push(ROUTES.STATUS_429.path)
      }
  
      return Promise.reject(error);
    },
  );
};